import PropTypes from 'prop-types'
import React, { Suspense, lazy } from 'react'
import ScrollToTop from './helpers/scroll-top'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { multilanguage } from 'redux-multilanguage'
import { connect } from 'react-redux'
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic'
import RedirectModal from './pages/other/RedirectModal'

// Página Principal

const HomeFashionEight = lazy(() => import('./pages/home/HomeFashionEight'))

// shop pages
const ShopGridStandard = lazy(() => import('./pages/shop/ShopGridStandard'))
const ShopGridFilter = lazy(() => import('./pages/shop/ShopGridFilter'))
const ShopGridTwoColumn = lazy(() => import('./pages/shop/ShopGridTwoColumn'))
const ShopGridNoSidebar = lazy(() => import('./pages/shop/ShopGridNoSidebar'))
const ShopGridFullWidth = lazy(() => import('./pages/shop/ShopGridFullWidth'))
const ShopGridRightSidebar = lazy(() =>
  import('./pages/shop/ShopGridRightSidebar')
)
const ShopListStandard = lazy(() => import('./pages/shop/ShopListStandard'))
const ShopListFullWidth = lazy(() => import('./pages/shop/ShopListFullWidth'))
const ShopListTwoColumn = lazy(() => import('./pages/shop/ShopListTwoColumn'))

// Pagina de Produtos
const Product = lazy(() => import('./pages/shop-product/Product'))
// Outras paginas
const Contact = lazy(() => import('./pages/other/Contact'))
const Servicos = lazy(() => import('./pages/other/Servicos'))
const QuemSomos = lazy(() => import('./pages/other/QuemSomos'))

const NotFound = lazy(() => import('./pages/other/NotFound'))

const App = props => {
  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <RedirectModal />
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                {/* Página Principal */}
                <Route
                  exact
                  path={process.env.PUBLIC_URL + '/'}
                  component={HomeFashionEight}
                />

                {/* Shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + '/shop-grid-standard'}
                  component={ShopGridStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/shop-grid-filter'}
                  component={ShopGridFilter}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/shop-grid-two-column'}
                  component={ShopGridTwoColumn}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/shop-grid-no-sidebar'}
                  component={ShopGridNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/shop-grid-full-width'}
                  component={ShopGridFullWidth}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/shop-grid-right-sidebar'}
                  component={ShopGridRightSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/shop-list-standard'}
                  component={ShopListStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/shop-list-full-width'}
                  component={ShopListFullWidth}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/shop-list-two-column'}
                  component={ShopListTwoColumn}
                />

                {/* Produtos ponto e companhia */}
                <Route
                  path={process.env.PUBLIC_URL + '/product/:id'}
                  render={routeProps => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                {/* Outras Páginas */}
                <Route
                  path={process.env.PUBLIC_URL + '/contact'}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/servicos'}
                  component={Servicos}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/quemsomos'}
                  component={QuemSomos}
                />

                <Route
                  path={process.env.PUBLIC_URL + '/not-found'}
                  component={NotFound}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  )
}

App.propTypes = {
  dispatch: PropTypes.func
}

export default connect()(multilanguage(App))
