import React, { useEffect } from 'react'

const RedirectModal = ({ redirectUrl, delay = 3000 }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://solutech.tec.br/'
    }, delay)

    return () => clearTimeout(timer)
  }, [redirectUrl, delay])

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h1>Ponto & Companhia agora é solutech!</h1>
        <h2>Aguarde...</h2>
        <p>Você será redirecionado em breve.</p>
      </div>
    </div>
  )
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    fontFamily: 'Roboto Slab, serif'
  }
}

export default RedirectModal
